<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <b-row>
      <b-col md="9">
        <h2 v-if="template === 'journal'">
          Journal Email Template
        </h2>
        <h2 v-if="template === 'desktopTemplate'">
          Desktop Email Template
        </h2>
        <h2 v-if="template === 'desktop'">
          Desktop Update
        </h2>
        <h2 v-if="template === 'internal'">
          Internal Journal Email Template
        </h2>
        <h2 v-if="template === 'collections'">
          Journal Collections Template
        </h2>
        <h2 v-if="template === 'dailyUpdate'">
          Journal Daily Update
        </h2>
        <h2 v-if="template === 'finalReport'">
          Journal Final Report
        </h2>
        <h2 v-else-if="template === 'activity'">
          Activity Update Email Template
        </h2>
        <h2 v-else-if="template === 'client'">
          Client Update Email Template
        </h2>
        <h2 v-else-if="template === 'reopen'">
          Create Reopen Email Reminder Template
        </h2>
        <h2 v-else-if="template === 'license'">
          Create License Reminder Email Template
        </h2>
        <div class="white-bg-container">
          <b-row>
            <b-col>
              <b-form-group
                label="FROM:"
                label-for="from"
                label-cols-sm="2"
              >
                <b-form-input
                  id="from"
                  v-model="From"
                  placeholder=""
                  name="from"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="TO:"
                label-for="to"
                label-cols-sm="2"
              >
                <b-form-input
                  id="to"
                  v-model="To"
                  placeholder=""
                  name="to"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="CC:"
                label-for="cc"
                label-cols-sm="2"
              >
                <b-form-input
                  id="cc"
                  v-model="Cc"
                  placeholder=""
                  name="cc"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="BCC:"
                label-for="bcc"
                label-cols-sm="2"
              >
                <b-form-input
                  id="bcc"
                  v-model="Bcc"
                  placeholder=""
                  name="bcc"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="SUBJECT:"
                label-for="subject"
                label-cols-sm="2"
              >
                <b-form-input
                  id="subject"
                  v-model="Subject"
                  placeholder=""
                  name="subject"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="template !== 'collections' && template !== 'journal' && template !== 'desktopTemplate' && template !== 'desktop' &&
                  template !== 'internal' && template !== 'finalReport' &&
                  template !== 'dailyUpdate' ? 'Email Body Section 1 (Non User Editable):' : 'Email Body Section:'"
                label-for="emailBodySection1"
              >
                <div
                  id="emailBodySection1"
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedEmailBodySection1}"
                >
                  <textarea
                    v-model="BodySection1"
                    class="expandable-textarea"
                    :class="[isExpandedEmailBodySection1 ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedEmailBodySection1 = !isExpandedEmailBodySection1">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedEmailBodySection1 ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="template !== 'collections' && template !== 'journal' && template !== 'desktopTemplate' && template !== 'desktop' &&
            template !== 'internal' && template !== 'finalReport' && template !== 'dailyUpdate'">
            <b-col>
              <b-form-group
                label="Email Body Section 2 (User Editable):"
                label-for="emailBodySection2"
              >
                <div
                  id="emailBodySection2"
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedEmailBodySection2}"
                >
                  <textarea
                    v-model="BodySection2"
                    class="expandable-textarea"
                    :class="[isExpandedEmailBodySection2 ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedEmailBodySection2 = !isExpandedEmailBodySection2">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedEmailBodySection2 ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="template !== 'collections' && template !== 'journal' && template !== 'desktopTemplate' && template !== 'desktop' &&
            template !== 'internal' && template !== 'finalReport' && template !== 'dailyUpdate'">
            <b-col>
              <b-form-group
                label="Email Body Section 3 (Non User Editable):"
                label-for="emailBodySection3"
              >
                <div
                  id="emailBodySection3"
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedEmailBodySection3}"
                >
                  <textarea
                    v-model="BodySection3"
                    class="expandable-textarea"
                    :class="[isExpandedEmailBodySection3 ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedEmailBodySection3 = !isExpandedEmailBodySection3">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedEmailBodySection3 ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col class="pt-3">
        <div class="white-bg-container">
          <b-row class="pb-1">
            <b-col>
              Template Tokens
            </b-col>
          </b-row>
          <b-row
            v-for="tempToken in TemplateTokens"
            :key="tempToken"
          >
            <b-col>
              {{ tempToken }}
            </b-col>
          </b-row>

        </div>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveUpdateEmail()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      From: "",
      To: "",
      Cc: "",
      Bcc: "",
      Subject: "",
      BodySection1: "",
      BodySection2: "",
      BodySection3: "",

      caseObj: {},
      userObj: {},
      JournalTitle: "",

      readOnly: false,
      isExpandedEmailBodySection1: false,
      isExpandedEmailBodySection2: false,
      isExpandedEmailBodySection3: false,

      template: "",
      templateID: "",

      TemplateTokens: [],
      validEmail: true,
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Templates',
          href: '/admin/templates'
        },
        {
          text: this.textTemplate(this.template),
        },
      ]
    },
  },
  watch: {
    From(val) {
      const output = this.getEmailsFromString(val);
      if (output) this.From = output
    },
    To(val) {
      const output = this.getEmailsFromString(val);
      if (output) this.To = output
    },
    Cc(val) {
      const output = this.getEmailsFromString(val);
      if (output) this.Cc = output
    },
    Bcc(val) {
      const output = this.getEmailsFromString(val);
      if (output) this.Bcc = output
    },
  },
  async created() {
    this.template = this.$route.meta.template;
    this.templateID = this.$route.name;
    try {
      this.getTemplate(this.templateID);
      this.getTemplateTokens(this.templateID);
      this.getUserInfo();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getCase() {
      const CaseID = this.$route.params.caseId;
      this.JournalTitle = this.$route.params.journalTitle;
      apiService
          .get("case/" + CaseID + "/detail")
          .then(res => {
            this.caseObj = res.data;
          })
    },
    getUserInfo() {
      apiService
          .get("auth/userinfo")
          .then(res => {
            this.userObj = res.data;
          })
    },
    getTemplateTokens(templateID) {
      apiService
          .get("template/" + templateID + "/tokens")
          .then(res => {
            this.TemplateTokens = res.data;
          })
    },
    getTemplate(templateID) {
      apiService
          .get("template/" + templateID)
          .then(res => {
            this.From = res.data.templateData.From;
            this.To = res.data.templateData.To;
            this.Cc = res.data.templateData.CC;
            this.Bcc = res.data.templateData.BCC;
            this.Subject = res.data.templateData.Subject;
            this.BodySection1 = res.data.templateData.BodySection1;
            this.BodySection2 = res.data.templateData.BodySection2;
            this.BodySection3 = res.data.templateData.BodySection3;
          })
    },
    textTemplate(val) {
      switch (val) {
        case "journal" :
          return "Journal Email Template";
        case "desktopTemplate" :
          return "Desktop Email Template";
        case "desktop" :
          return "Desktop Update";
        case "internal" :
          return "Internal Journal Email Template";
        case "collections" :
          return "Journal Collections Template";
        case "dailyUpdate" :
          return "Journal Daily Update";
        case "finalReport" :
          return "Journal Final Report";
        case "activity" :
          return "Activity Email Template";
        case "client" :
          return "Client Email Template";
        case "reopen" :
          return "Reopen Email Reminder Template";
        case "managerNotification" :
          return "Operations Manager Notification";
        case "license" :
          return "License Reminder Email Template";
      }
    },
    isEmpty(val) {
      return val ? val : "";
    },
    resetForm() {
      this.$router.push('/admin/templates');
    },
    saveUpdateEmail() {
      if (!this.validEmail) {
        this.showToast('danger', 'top-center', 4000, 'Email format is incorrect.');
        return
      }
      let postData = {
        TemplateData: {
          "From": this.From,
          "To": this.To,
          "CC": this.Cc,
          "BCC": this.Bcc,
          "Subject": this.Subject,
          "BodySection1": this.BodySection1,
        }
      }
      if (this.template !== 'collections' && this.template !== 'journal' && this.template !== 'desktopTemplate' && this.template !== 'desktop' &&
          this.template !== 'internal' && this.template !== 'dailyUpdate' && this.template !== 'finalReport') {
        postData.TemplateData = {...postData.TemplateData,
          "BodySection2": this.BodySection2,
          "BodySection3": this.BodySection3
        }
      }
      apiService.post("template/" + this.templateID, postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Email form update!');
        }
      })
    },
    validateEmail(email) {
      const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return re.test(String(email).toLowerCase());
    },

    getEmailsFromString(val) {
      if (!val) return;
      const startSubStr = val.indexOf("<");
      const endSubStr = val.indexOf(">");
      if (startSubStr !== -1 && endSubStr !== -1) {
        const OldSubStr = String(val.slice(startSubStr + 1, endSubStr));
        let SubStr = OldSubStr;
        SubStr = SubStr.replace(/\s+/g, '');
        if (SubStr[0] === "{") return
        this.validEmail = this.validateEmail(SubStr)
        if (this.validEmail) {
          return val.replace(OldSubStr, SubStr)
        }
      }
    },
  }
}
</script>

<style scoped>

</style>